import React, { useState, useEffect } from "react";

const ImageSlideshow = ({ images, interval = 3000 }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, interval);

    return () => clearInterval(intervalId);
  }, [images.length, interval]);

  return (
    <div>
      <img
        className="mx-auto"
        src={images[currentImageIndex]}
        alt={`Slide ${currentImageIndex}`}
      />
    </div>
  );
};

export default ImageSlideshow;
