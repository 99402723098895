export const apiPath = {
  // Authntication
  sendOTP: "auth/send-otp",
  verifyOTP: "auth/verify-otp",
  register: "auth/register",
  logout: "auth/logout",

  // User
  profile: "users/",
  getProfile: "users/get-user-data",
  uploadAvatar: "users/upload-avatar",
  sendMessage: "users/send-message",
  topWinners: "users/top-winners",
  getPackages: "users/get-packages",
  saveExpoToken: "users/expo-token",
  refralCode: "users/genrate-refral-code",

  // Game
  loadGame: "users/load-game",
  playGame: "users/play-game",
  stopGame: "users/stop-game",

  //match
  checkMatch: "users/check-match",
  findMatch: "users/find-match",
  startMatch: "users/start-match",
  stopMatch: "users/stop-match",
  exitMatch: "users/exit-match",

  // Transaction

  createOrder: "users/create-order",
  withdrawMoney: "users/withdraw",
  depositMoney: "users/deposit-money",
  withdrawMoneyConfigs: "users/withdraw-configs",
  transactions: "users/transactions",
  purchaseCoins: "users/buy-conis",
  todayEarnings: "users/earnings",
  purchaseHistroy: "users/purchase-histroy",
  verifyBankAccount: "users/verify-bank-account",
  paymentFail: "users/payment-fail",
};

export const SERVER_URL = "https://spinloottech.com/";
//export const SERVER_URL = "http://192.168.1.2:3000/";
export const API_URL = SERVER_URL + "v1/";
