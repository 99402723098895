import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import apiReducer from "./apiSlice";
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["auth"],
};

const authConfig = {
  key: "auth",
  storage,
  // blacklist: ["isGoogleExist"],
  // whitelist: ["user", "isLoggedIn"],
};

const reducer = {
  api: persistReducer(authConfig, apiReducer),
};
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({ ...reducer })
);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
});
export const persistor = persistStore(store);
