import { Button, Card, CardContent, Slider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppBarMain from "../components/AppBar";
import { useNavigate } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { calculateInterest } from "../config";
import ImageSlideshow from "../components/ImageSlideshow";
import { Calculate, InstallMobile, TrendingUp } from "@mui/icons-material";

function Home() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [depositAmount, setDepositAmount] = useState(10000);
  const navigate = useNavigate();
  const images = ["slide1.png", "slide2.png", "slide3.png"];
  const handleSliderChange = (event, newValue) => {
    setDepositAmount(newValue);
  };

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }

        setDeferredPrompt(null);
      });
    }
  };

  return (
    <div>
      {deferredPrompt && (
        <div className="p-2 fixed bottom-0 w-full bg-white">
          <Button
            color="success"
            startIcon={<InstallMobile />}
            fullWidth={true}
            variant="contained"
            onClick={handleInstallClick}
          >
            Install App
          </Button>
        </div>
      )}

      <div className="container mx-auto">
        <AppBarMain />
        <section className="bg-white shadow-sm" style={{ marginTop: "56px" }}>
          <ImageSlideshow images={images} interval={6000} />
        </section>
        <div className="p-4">
          <div className="mt-2 mb-8 text-center">
            <h4 className="text-xl font-bold">
              1xCash के साथ प्रतिदिन ब्याज कमाएं
            </h4>
            <p className="px-12">
              साधारण मार्केट ब्याज दर की जगह रोजाना अपने इन्वेस्टमेंट पर 1-2% का
              ब्याज प्राप्त करे
            </p>
          </div>

          <section className="mb-8">
            <Card className="p-2">
              <CardContent>
                <h2 className="mb-4 font-medium text-lg border-b pb-4">
                  <Calculate /> Investment Calculator
                </h2>

                <Typography
                  gutterBottom
                  variant="body"
                  component="div"
                  className="mb-4"
                >
                  Amount:{" "}
                  <strong>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(depositAmount)}
                  </strong>
                </Typography>
                <Slider
                  value={depositAmount}
                  onChange={handleSliderChange}
                  min={5000}
                  max={100000}
                  step={5000}
                />

                <div className="mt-2">
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <span className="text-gray-500 text-xs">
                              Time Period
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span className="text-gray-500 text-xs">
                              Earning
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span className="text-gray-500 text-xs">
                              Total Return
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>1 Day</TableCell>
                          <TableCell align="right">
                            {calculateInterest(depositAmount)[0].interest}
                            <span className="font-medium text-xs text-green-500 ml-1">
                              ({calculateInterest(depositAmount)[0].growthRate})
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span className="font-bold  text-gray-700">
                              {calculateInterest(depositAmount)[0].totalReturn}
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>1 Month</TableCell>
                          <TableCell align="right">
                            {calculateInterest(depositAmount)[1].interest}
                            <span className="font-medium text-xs text-green-500 ml-1">
                              ({calculateInterest(depositAmount)[1].growthRate})
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span className="font-bold  text-gray-700">
                              {calculateInterest(depositAmount)[1].totalReturn}
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>1 Year</TableCell>
                          <TableCell align="right">
                            {calculateInterest(depositAmount)[2].interest}
                            <span className="font-medium text-xs text-green-500 ml-1">
                              ({calculateInterest(depositAmount)[2].growthRate})
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span className="font-bold  text-gray-700">
                              {calculateInterest(depositAmount)[2].totalReturn}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>

                <div className="mt-8">
                  <Button
                    startIcon={<TrendingUp />}
                    fullWidth={true}
                    onClick={() => {
                      navigate("/login");
                    }}
                    size="large"
                    variant="outlined"
                  >
                    Invest Now
                  </Button>
                </div>
              </CardContent>
            </Card>
          </section>

          <section className="mb-8 bg-white p-4 rounded-md">
            <h4 className="text-lg font-bold">Rules and Regulations</h4>
            <ul className="list-disc mt-4 ml-6">
              <li className="mb-2">
                <span className="mr-2">⚖️</span> Deposits must be between ₹5000
                and ₹100000.
              </li>
              <li className="mb-2">
                <span className="mr-2">💸</span> You can withdraw your funds at
                any time, but it may take up to 24 hours to process.
              </li>
              <li className="mb-2">
                <span className="mr-2">📅</span> Earnings are available on
                weekdays only. They will not be credited on government holidays.
              </li>
            </ul>

            <table className="w-full table-auto mt-4">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">Day</th>
                  <th className="px-4 py-2 text-left">Earning</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-4 py-2">Monday</td>
                  <td className="border px-4 py-2">✅</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Tuesday</td>
                  <td className="border px-4 py-2">✅</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Wednesday</td>
                  <td className="border px-4 py-2">✅</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Thursday</td>
                  <td className="border px-4 py-2">✅</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Friday</td>
                  <td className="border px-4 py-2">✅</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Saturday</td>
                  <td className="border px-4 py-2">❌</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Sunday</td>
                  <td className="border px-4 py-2">❌</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Holidays</td>
                  <td className="border px-4 py-2">❌</td>
                </tr>
              </tbody>
            </table>
          </section>

          <section className="mb-8">
            <h2 className="text-lg font-bold">How It Works</h2>
            <p className="mt-4">
              Our app utilizes advanced AI technology to navigate the global
              trading market. These AI bots are equipped with sophisticated
              algorithms that analyze market trends and make data-driven
              decisions to maximize returns.
            </p>
            <p className="mt-2">
              These bots have a proven track record of accuracy, ensuring a
              minimum 1% return on your investments. By harnessing the power of
              AI, we provide you with a reliable and efficient way to grow your
              wealth.
            </p>
          </section>

          <section>
            <h2 className="text-lg font-bold">Contact Info</h2>
            <a href="mailto:info@100ka2.com" className="mt-4">
              info@1xCash.com
            </a>
            <div className="mt-4">
              <Button
                startIcon={<WhatsAppIcon />}
                onClick={() => {
                  window.open("https://wa.me/916367193252", "_blank");
                }}
                variant="outlined"
                color="success"
              >
                Chat on WhatsApp
              </Button>
            </div>
          </section>
        </div>
      </div>

      <footer className="bg-gray-800 text-white py-4 mt-8">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 1xCash. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default Home;
