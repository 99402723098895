import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CircularProgress, Typography } from "@mui/material";
import AppBarSecondary from "../components/AppBarSecondary";
import { useDispatch } from "react-redux";
import { depositMoneyAPI } from "../api/apiSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

function PaymentStatus() {
  const dispatch = useDispatch();
  const { order_id } = useParams();
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState("checking");

  useEffect(() => {
    let isMounted = true;

    if (paymentStatus === "checking") {
      dispatch(depositMoneyAPI({ order_id: order_id })).then((result) => {
        if (isMounted) {
          if (result.payload.status === "SUCCESS") {
            setPaymentStatus("success");
            console.log(result);
          } else {
            setPaymentStatus("error");
            console.error("Some error occurred. Please try again later.");
          }
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [dispatch, order_id, paymentStatus]);

  let content;
  if (paymentStatus === "checking") {
    content = (
      <div className="mx-4 mt-56 text-center">
        <CircularProgress />
        <Typography variant="subtitle1" className="mt-4">
          Checking payment status...
        </Typography>
      </div>
    );
  } else if (paymentStatus === "success") {
    content = (
      <div className="mx-4 mt-56 text-center">
        <CheckCircleIcon style={{ fontSize: 100, color: "green" }} />
        <Typography variant="h5" className="mt-4">
          Payment Successful
        </Typography>
        <Typography variant="subtitle1" className="mt-2">
          Thank you for your payment.
        </Typography>
        <div className="mt-4">
          <Button
            variant="contained"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Dashboard
          </Button>
        </div>
      </div>
    );
  } else if (paymentStatus === "error") {
    content = (
      <div className="mx-4 mt-56 text-center">
        <ErrorIcon style={{ fontSize: 100, color: "red" }} />
        <Typography variant="h5" className="mt-4">
          Payment Failed
        </Typography>
        <Typography variant="subtitle1" className="mt-2">
          We encountered an error processing your payment. Please try again
          later.
        </Typography>
        <div className="mt-4">
          <Button
            variant="contained"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Dashboard
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <AppBarSecondary title={"Payment Status"} />
      {content}
    </div>
  );
}

export default PaymentStatus;
