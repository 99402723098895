import React, { useState } from "react";
import AppBarSecondary from "../components/AppBarSecondary";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Slider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { calculateInterest } from "../config";
import { createOrderAPI, depositMoneyAPI } from "../api/apiSlice";
import { useNavigate } from "react-router-dom";

import { load } from "@cashfreepayments/cashfree-js";

let cashfree;
var initializeSDK = async function () {
  cashfree = await load({
    mode: "production",
  });
};
initializeSDK();

function AddMoney() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [paymentSessionID, setPaymentSessionID] = useState();
  const userData = useSelector((state) => state.api.user);
  const [depositAmount, setDepositAmount] = useState(10000);
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false);
  const handleSliderChange = (event, newValue) => {
    setDepositAmount(newValue);
  };
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    dispatch(createOrderAPI({ amount: depositAmount })).then((result) => {
      if (result) {
        setPaymentSessionID(result.payload.payment_session_id);
        Checkout(result.payload.payment_session_id);
      } else {
        alert("Some error ocuured please try again later");
      }
    });

    //setOpen(true);
  };

  function Checkout(sid) {
    let checkoutOptions = {
      paymentSessionId: sid,
      redirectTarget: "_self",
      returnUrl: "https://1xcash.in/payment-status/{order_id}",
    };
    cashfree?.checkout(checkoutOptions);
  }

  return (
    <div>
      <AppBarSecondary title={"Add Money"} />
      <div className="px-4 mt-20">
        {showConfirmationScreen ? (
          <div className="text-center mt-32">
            <CircularProgress />
            <h2 className="mt-2 font-bold text-lg">Confirming Payment...</h2>
            <p className="mx-8 mb-8">
              Please send Payment screenshoot after payment completed.
            </p>

            <Button
              startIcon={<WhatsAppIcon />}
              onClick={() => {
                window.open("https://wa.me/916367193252", "_blank");
              }}
              variant="contained"
              color="success"
            >
              Upload Payment screenshoot
            </Button>
            <div className="mt-4 mx-12">
              <Button
                fullWidth={true}
                onClick={() => {
                  navigate("/dashboard");
                }}
                variant="outlined"
              >
                Done
              </Button>
            </div>
          </div>
        ) : (
          <Card>
            <CardContent>
              <h1 className="text-center text-2xl mt-4 font-bold">
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(depositAmount)}
              </h1>
              <p className="text-gray-400 text-center mb-8">
                Amount to be deposited
              </p>
              <div className="px-4">
                <Slider
                  aria-label="Temperature"
                  valueLabelDisplay="auto"
                  marks
                  value={depositAmount}
                  onChange={handleSliderChange}
                  min={5000}
                  max={100000}
                  step={5000}
                />
              </div>

              <div className="mt-2">
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <span className="text-gray-500 text-xs">
                            Time Period
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="text-gray-500 text-xs">Earning</span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="text-gray-500 text-xs">
                            Total Return
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>1 Day</TableCell>
                        <TableCell align="right">
                          {calculateInterest(depositAmount)[0].interest}
                          <span className="font-medium text-xs text-green-500 ml-1">
                            ({calculateInterest(depositAmount)[0].growthRate})
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="font-bold  text-gray-700">
                            {calculateInterest(depositAmount)[0].totalReturn}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1 Month</TableCell>
                        <TableCell align="right">
                          {calculateInterest(depositAmount)[1].interest}
                          <span className="font-medium text-xs text-green-500 ml-1">
                            ({calculateInterest(depositAmount)[1].growthRate})
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="font-bold  text-gray-700">
                            {calculateInterest(depositAmount)[1].totalReturn}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1 Year</TableCell>
                        <TableCell align="right">
                          {calculateInterest(depositAmount)[2].interest}
                          <span className="font-medium text-xs text-green-500 ml-1">
                            ({calculateInterest(depositAmount)[2].growthRate})
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="font-bold  text-gray-700">
                            {calculateInterest(depositAmount)[2].totalReturn}
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <div className="mt-8">
                <Button
                  onClick={handleClickOpen}
                  size={"large"}
                  variant="contained"
                  color="success"
                  fullWidth={true}
                >
                  Add Money
                </Button>
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
}

export default AddMoney;
