import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL, apiPath } from "./apiPath";

// Create an Axios instance with a custom configuration
const api = axios.create({
  baseURL: API_URL + "/users",
});

// Add a response interceptor to handle 401 errors
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle the 401 error here
      // For example, you can dispatch the logout action
      // store.dispatch(logout());
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export const loginAPI = createAsyncThunk("api/login", async (credentials) => {
  const response = await api.post(API_URL + apiPath.sendOTP, credentials);
  return response.data;
});

export const verifyOtpAPI = createAsyncThunk(
  "api/verifyOtpAPI",
  async (credentials) => {
    const response = await api.post(API_URL + apiPath.verifyOTP, credentials);
    return response.data;
  }
);

export const getUserData = createAsyncThunk(
  "api/getUserData",
  async (props, { getState }) => {
    const token = getState().api.tokens;
    const response = await api.post(API_URL + apiPath.getProfile, props, {
      headers: {
        Authorization: `Bearer ${token.access.token}`,
      },
    });
    return response.data;
  }
);

export const withdrawMoneyAPI = createAsyncThunk(
  "api/withdrawMoneyAPI",
  async (props, { getState }) => {
    const token = getState().api.tokens;
    const response = await api.post(API_URL + apiPath.withdrawMoney, props, {
      headers: {
        Authorization: `Bearer ${token.access.token}`,
      },
    });
    return response.data;
  }
);

export const depositMoneyAPI = createAsyncThunk(
  "api/depositMoneyAPI",
  async (props, { getState }) => {
    const token = getState().api.tokens;
    const response = await api.post(API_URL + apiPath.depositMoney, props, {
      headers: {
        Authorization: `Bearer ${token.access.token}`,
      },
    });
    return response.data;
  }
);

export const createOrderAPI = createAsyncThunk(
  "api/createOrderAPI",
  async (props, { getState }) => {
    const token = getState().api.tokens;
    const response = await api.post(API_URL + apiPath.createOrder, props, {
      headers: {
        Authorization: `Bearer ${token.access.token}`,
      },
    });
    return response.data;
  }
);

export const apiSlice = createSlice({
  name: "api",
  initialState: {
    isAuthenticated: false,
    user: null,
    tokens: null,
    status: "idle",
    error: null,
    users: [],
    withdrawRequests: [],
    transactions: [],
    gameLogs: [],
    gameSettings: {},
  },
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.dashData = null;
      state.user = null;
      state.tokens = null;
      state.status = "idle";
      state.error = null;
      state.users = [];
      state.withdrawRequests = [];
      state.transactions = [];
      state.gameLogs = [];
      state.gameSettings = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAPI.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginAPI.fulfilled, (state, action) => {
        state.status = "succeeded";
        // state.user = action.payload;
      })
      .addCase(loginAPI.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(verifyOtpAPI.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyOtpAPI.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.status = "succeeded";
          // state.user = action.payload;
          state.isAuthenticated = true;
          state.tokens = action.payload.data.tokens;
          state.user = action.payload.data.user;
        }
      })
      .addCase(verifyOtpAPI.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(getUserData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dashData = action.payload;
        state.user = action.payload.user;
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    // .addCase(getAdminData.fulfilled, (state, action) => {
    //   if (action.payload.type == 1) {
    //     state.users = action.payload.data;
    //   }
    //   if (action.payload.type == 2) {
    //     state.withdrawRequests = action.payload.data;
    //   }

    //   if (action.payload.type == 3) {
    //     state.transactions = action.payload.data;
    //   }
    //   if (action.payload.type == 4) {
    //     state.gameLogs = action.payload.data;
    //   }

    //   if (action.payload.type == 5) {
    //     state.gameSettings = action.payload.data;
    //   }
    // })
    // .addCase(getAdminData.rejected, (state, action) => {
    //   console.log(action.payload);
    // })
    // .addCase(updateUserData.fulfilled, (state, action) => {
    //   if (action.payload.success) {
    //     state.users = action.payload.data;
    //   }
    // })
    // .addCase(deleteUser.fulfilled, (state, action) => {
    //   if (action.payload.success) {
    //     state.users = action.payload.data;
    //   }
    // })

    // .addCase(payWithdrawRequest.fulfilled, (state, action) => {
    //   if (action.payload) {
    //     state.withdrawRequests = action.payload;
    //   }
    // });
  },
});

export const { logout } = apiSlice.actions;
export default apiSlice.reducer;
