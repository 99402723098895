import React, { useState } from "react";
import AppBarMain from "../components/AppBar";
import { useDispatch } from "react-redux";
import { loginAPI, verifyOtpAPI } from "../api/apiSlice";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";

const Login = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [sending, setSending] = useState(false);
  const [otp, setOtp] = useState("");
  const [isMobileNumberSubmitted, setIsMobileNumberSubmitted] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleMobileNumberSubmit = (e) => {
    e.preventDefault();
    // Basic validation for mobile number
    if (mobileNumber.trim() === "") {
      setError("Mobile number is required");
      return;
    }
    setSending(true);
    dispatch(loginAPI({ phone: mobileNumber })).then((result) => {
      if (result.payload.success) {
        setIsMobileNumberSubmitted(true);
        setError("");
        setSending(false);
      } else {
        alert(result.payload.message);
      }
    });
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    // Basic validation for OTP
    if (otp.trim() === "") {
      setError("OTP is required");
      return;
    }
    dispatch(verifyOtpAPI({ otp: otp, phone: mobileNumber })).then((result) => {
      if (result.payload.success) {
        navigate("/dashboard");
      } else {
        alert(result.payload.message);
      }
    });
  };

  return (
    <div>
      <AppBarMain />
      <div className="container mt-16 p-4">
        {error && <p className="text-red-500 mb-2">{error}</p>}
        {!isMobileNumberSubmitted ? (
          <form onSubmit={handleMobileNumberSubmit}>
            <div className="mb-4">
              <TextField
                inputRef={(input) => input && input.focus()}
                label="Mobile Number"
                variant="outlined"
                type="number"
                id="mobileNumber"
                className="border rounded px-2 py-1 w-full"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
            </div>
            <button
              disabled={sending}
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              {sending ? "Sending..." : "Send OTP"}
            </button>
          </form>
        ) : (
          <form onSubmit={handleOtpSubmit}>
            <div className="mb-4">
              <TextField
                label="Enter OTP"
                type="number"
                id="otp"
                className="border rounded px-2 py-1 w-full"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Verify OTP
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
