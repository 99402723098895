export const calculateInterest = (amount) => {
  let intersest = amount * 0.02;
  let monthInterest = intersest * 21;
  let yearInterest = intersest * 21 * 12;

  let returnPercentage = (100 * intersest) / amount;
  let returnPercentageMonth = (100 * monthInterest) / amount;
  let returnPercentageYear = (100 * yearInterest) / amount;

  let totalReturn = intersest + amount;
  let totalReturnMonth = monthInterest + amount;
  let totalReturnYear = yearInterest + amount;

  return [
    {
      interest: convertToCurrency(intersest),
      growthRate: "+" + returnPercentage + "%",
      totalReturn: convertToCurrency(totalReturn),
    },
    {
      interest: convertToCurrency(monthInterest),
      growthRate: "+" + returnPercentageMonth + "%",
      totalReturn: convertToCurrency(totalReturnMonth),
    },
    {
      interest: convertToCurrency(yearInterest),
      growthRate: "+" + returnPercentageYear + "%",
      totalReturn: convertToCurrency(totalReturnYear),
    },
  ];
};

function convertToCurrency(amount) {
  let am = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(amount);
  am = am.split(".");
  return am[0];
}
