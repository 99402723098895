import React, { useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Page404 from "./pages/Page404";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { useSelector } from "react-redux";
import AddMoney from "./pages/AddMoney";
import WithdrawMoney from "./pages/WithdrawMoney";
import PaymentStatus from "./pages/PaymentStatus";

function App() {
  const isAuthenticated = useSelector((state) => state.api.isAuthenticated);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/payment-status/:order_id" element={<PaymentStatus />} />

        <Route
          path="login"
          element={!isAuthenticated ? <Login /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/dashboard"
          element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
        />
        <Route
          path="/add-money"
          element={isAuthenticated ? <AddMoney /> : <Navigate to="/login" />}
        />
        <Route
          path="/withdraw-money"
          element={
            isAuthenticated ? <WithdrawMoney /> : <Navigate to="/login" />
          }
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
