import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import { Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { AccountCircle } from "@mui/icons-material";

function AppBarMain() {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.api.isAuthenticated);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          <img
            onClick={() => {
              navigate("/");
            }}
            width={128}
            src="logo.png"
          />

          <div className="ml-auto">
            {isAuthenticated ? (
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <IconButton
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </Box>
            ) : (
              <Button
                onClick={() => {
                  navigate("/login");
                }}
                color="inherit"
              >
                Login
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default AppBarMain;
