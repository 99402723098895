import React, { useState } from "react";
import AppBarSecondary from "../components/AppBarSecondary";
import { Button, Card, CardContent, Slider, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { withdrawMoneyAPI } from "../api/apiSlice";
import { useNavigate } from "react-router-dom";

function WithdrawMoney() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashData = useSelector((state) => state.api.dashData);
  const userData = useSelector((state) => state.api.user);
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [reqUPIID, setReqUPIID] = useState(userData?.vpa);
  const handleWithdraw = () => {
    const amount = parseFloat(withdrawAmount);
    if (isNaN(amount) || amount < 100) {
      setError("Minimum withdrawl amount is ₹100");
      return;
    }

    if (amount > dashData?.balance) {
      setError("Insufficient balance.");
      return;
    }

    if (reqUPIID == "") {
      setError2("Please enter a valid UPI address");
      return;
    }

    dispatch(withdrawMoneyAPI({ amount: amount, vpa: reqUPIID })).then(
      (result) => {
        if (result) {
          navigate("/dashboard");
          alert("Withdraw request created successfully!");
        } else {
          alert("Some error ocuured please try again later");
        }
      }
    );
  };

  const handleChangeid = (e) => {
    setReqUPIID(e.target.value);
    setError2(""); // Clearing error when input changes
  };

  const handleChange = (e) => {
    setWithdrawAmount(e.target.value);
    setError(""); // Clearing error when input changes
  };

  return (
    <div>
      <AppBarSecondary title={"Withdraw Money"} />
      <div className="px-4 mt-20">
        <Card>
          <CardContent>
            <h1 className="text-center  font-bold ">
              {new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
              }).format(dashData?.balance)}{" "}
              available
            </h1>
            <div className="mt-8">
              <TextField
                type="number"
                fullWidth={true}
                // inputRef={(input) => input && input.focus()}
                id="outlined-basic"
                label="Withdraw Amount"
                variant="outlined"
                value={withdrawAmount}
                onChange={handleChange}
                error={!!error}
                helperText={error}
              />
              <div className="mt-4">
                <TextField
                  type="text"
                  fullWidth={true}
                  id="outlined-basic"
                  label="UPI ID"
                  value={reqUPIID}
                  variant="outlined"
                  placeholder={"myupiid@paytm"}
                  onChange={handleChangeid}
                  error={!!error2}
                  helperText={
                    error2 != ""
                      ? error2
                      : "Enter UPI Id where you want to recive the money"
                  }
                />
              </div>
            </div>

            <div className="mt-8">
              <Button
                onClick={handleWithdraw}
                size={"large"}
                variant="contained"
                color="success"
                fullWidth={true}
              >
                Withdraw Money
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default WithdrawMoney;
