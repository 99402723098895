import AppBarMain from "../components/AppBar";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserData, logout } from "../api/apiSlice";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Button,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { AccountBalanceWallet, Payments } from "@mui/icons-material";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dashData, setDashData] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(getUserData({})).then((result) => {
      if (result.payload) {
        setDashData(result.payload);
        setLoading(false);
      } else {
        alert("Some error ocuured please try again later");
      }
    });
  }, []);

  return (
    <>
      <AppBarMain />

      <div className="container mx-auto p-4 pt-20">
        <div className="mb-2">My Dashboard</div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {loading ? (
            <div className="m-auto mt-56">
              <CircularProgress />
            </div>
          ) : (
            <>
              <Card>
                <CardContent>
                  <div className="flex items-center mb-2">
                    <Typography
                      variant="h5"
                      component="div"
                      className="ml-2 text-green-500"
                    >
                      {new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      }).format(dashData?.balance)}
                    </Typography>
                  </div>
                  <Typography variant="subtitle1" color="text.secondary">
                    Current Balance
                  </Typography>
                  <div className="mt-4">
                    <Button
                      startIcon={<AccountBalanceWallet />}
                      onClick={() => {
                        navigate("/withdraw-money");
                      }}
                      variant="outlined"
                    >
                      Withdraw
                    </Button>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(dashData?.investment)}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    className="mb-2"
                  >
                    Current Investment
                  </Typography>

                  <div className="mt-2">
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <small>Earning Period</small>
                            </TableCell>
                            <TableCell align="right">
                              <small>Earning Amount</small>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Today</TableCell>
                            <TableCell align="right">
                              <strong className="text-green-500">
                                {new Intl.NumberFormat("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                }).format(dashData?.todayEarning)}
                              </strong>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>This Month</TableCell>
                            <TableCell align="right">
                              {new Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                              }).format(dashData?.monthEarning)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>This Year</TableCell>
                            <TableCell align="right">
                              {new Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                              }).format(dashData?.annualEarning)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>All Time</TableCell>
                            <TableCell align="right">
                              {new Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                              }).format(dashData?.allTimeEarning)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className="mt-4">
                    <Button
                      startIcon={<Payments />}
                      onClick={() => {
                        navigate("/add-money");
                      }}
                      variant="outlined"
                    >
                      Add Money
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </>
          )}
        </div>
        <div className="my-8   w-full">
          <div className="px-4">
            <Button
              variant="text"
              fullWidth={true}
              color={"error"}
              onClick={() => {
                if (window.confirm("Are you sure?")) {
                  dispatch(logout());
                  navigate("/login");
                }
              }}
            >
              Logout
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
