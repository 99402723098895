import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { SiCashapp } from "react-icons/si";
import { Badge, Button, IconButton, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdLogout } from "react-icons/md";
import { logout } from "../api/apiSlice";

import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { AccountCircle, ArrowBack } from "@mui/icons-material";

function AppBarSecondary({ title }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.api.isAuthenticated);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          <div className="mr-auto z-10">
            <IconButton
              onClick={() => {
                navigate("/dashboard");
              }}
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
            >
              <ArrowBack />
            </IconButton>
          </div>

          <div className=" absolute w-full left-0">
            <div className="mx-auto w-fit">
              <Typography variant="h6" component="div">
                {title}
              </Typography>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default AppBarSecondary;
